"use strict";

var _defineProperty = require("/var/www/vhosts/oyp.fr/deploy_tools/apps/calm-panda/releases/20241115110151/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

Object.defineProperty(exports, "__esModule", {
  value: true
});

var shared_lib_1 = require("@oyp/shared-lib"); // Enlever en-dessous


function getUpdatedRequestDataAndFormConfigFlexible(requestData, permanentFormResources) {
  var formConfig = getFlexibleFormConfig(permanentFormResources, requestData);
  return {
    formConfig: formConfig,
    requestData: getUpdatedRequestDataFromFlexibleFormConfig(requestData, formConfig)
  };
}

exports.getUpdatedRequestDataAndFormConfigFlexible = getUpdatedRequestDataAndFormConfigFlexible;

function getUpdatedRequestDataFromFlexibleFormConfig(requestData, formConfig) {
  var updatedRequestData = _objectSpread({}, requestData);

  if (formConfig.disableEyelet) {
    updatedRequestData.eyelet = shared_lib_1.EYELET_TYPES.NONE;
  }

  if (!formConfig.disableEyeletInterval && !updatedRequestData.eyeletInterval) {
    updatedRequestData.eyeletInterval = shared_lib_1.EYELET_INTERVALS.HUNDRED;
  } else if (formConfig.disableEyeletInterval) {
    updatedRequestData.eyeletInterval = undefined;
  }

  if (formConfig.disableLamination) {
    updatedRequestData.laminationId = undefined;
  }

  if (formConfig.disableUseWhiteLayer) {
    updatedRequestData.whiteLayerType = shared_lib_1.WHITE_LAYER_TYPES.NONE;
  } // Gestion des dépendances entre options


  if (shared_lib_1.FLEXIBLE_EYELET_TYPES_WITH_INTERVAL.includes(requestData.eyelet || shared_lib_1.EYELET_TYPES.NONE) && !shared_lib_1.getArrayFromEnum(shared_lib_1.EYELET_INTERVALS).includes(requestData.eyeletInterval)) {
    updatedRequestData.eyeletInterval = shared_lib_1.EYELET_INTERVALS.HUNDRED;
  } else if (!shared_lib_1.FLEXIBLE_EYELET_TYPES_WITH_INTERVAL.includes(requestData.eyelet || shared_lib_1.EYELET_TYPES.NONE)) {
    updatedRequestData.eyeletInterval = undefined;
  }

  return updatedRequestData;
} // Enlever au-dessus


function onFlexibleRequestDataChange(permanentResources, requestData) {
  var formConfig = getFlexibleFormConfig(permanentResources, requestData);
  var eyelet = formConfig.disableEyelet || !requestData.eyelet ? shared_lib_1.EYELET_TYPES.NONE : requestData.eyelet; //Cas des impressions miroir au switch de la matière transparent vers non transparent

  var printingType = formConfig.disableUseWhiteLayer && requestData.printingType === shared_lib_1.PRINTING_TYPES.MIRROR ? shared_lib_1.PRINTING_TYPES.ONE_SIDED : requestData.printingType;
  return _objectSpread({}, requestData, {
    printingType: printingType,
    laminationId: formConfig.disableLamination ? undefined : requestData.laminationId,
    whiteLayerType: formConfig.disableUseWhiteLayer ? shared_lib_1.WHITE_LAYER_TYPES.NONE : requestData.whiteLayerType,
    eyelet: eyelet,
    eyeletInterval: getEyeletInterval(eyelet, requestData.eyeletInterval)
  });
}

exports.onFlexibleRequestDataChange = onFlexibleRequestDataChange;

function getEyeletInterval(eyelet, interval) {
  var intervalOrDefault = interval || shared_lib_1.EYELET_INTERVALS.HUNDRED;
  return shared_lib_1.FLEXIBLE_EYELET_TYPES_WITH_INTERVAL.includes(eyelet) ? intervalOrDefault : undefined;
}

function getFlexibleFormConfig(permanentResources, requestData) {
  var materialId = requestData.materialId,
      selectedEyelet = requestData.eyelet,
      printingType = requestData.printingType;
  var material = permanentResources.materials.find(function (m) {
    return m.id === materialId;
  });
  var lamination = material.lamination,
      eyelet = material.eyelet,
      whiteLayer = material.whiteLayer;
  var disableLamination = !lamination;
  var disableEyelet = !eyelet;
  var disableEyeletInterval = disableEyelet || !selectedEyelet || !shared_lib_1.FLEXIBLE_EYELET_TYPES_WITH_INTERVAL.includes(selectedEyelet);
  return {
    disableLamination: disableLamination,
    disableEyelet: disableEyelet,
    disableEyeletInterval: disableEyeletInterval,
    disableUseWhiteLayer: !whiteLayer || printingType === shared_lib_1.PRINTING_TYPES.NONE
  };
}

exports.getFlexibleFormConfig = getFlexibleFormConfig;

function translateFlexibleWhiteLayerType(printingType, whiteLayerType) {
  if (printingType === shared_lib_1.PRINTING_TYPES.MIRROR) {
    if (whiteLayerType === shared_lib_1.WHITE_LAYER_TYPES.SELECTIVE) {
      return shared_lib_1.WHITE_LAYER_TYPE_TRANSLATIONS[shared_lib_1.WHITE_LAYER_TYPES.SELECTIVE_MIRROR];
    } else if (whiteLayerType === shared_lib_1.WHITE_LAYER_TYPES.TOTAL) {
      return shared_lib_1.WHITE_LAYER_TYPE_TRANSLATIONS[shared_lib_1.WHITE_LAYER_TYPES.TOTAL_MIRROR];
    }
  }

  return shared_lib_1.WHITE_LAYER_TYPE_TRANSLATIONS[whiteLayerType];
}

exports.translateFlexibleWhiteLayerType = translateFlexibleWhiteLayerType;