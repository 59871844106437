"use strict";

var _defineProperty = require("/var/www/vhosts/oyp.fr/deploy_tools/apps/calm-panda/releases/20241115110151/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _exports$MONTH_TEXT_T;

Object.defineProperty(exports, "__esModule", {
  value: true
});

function stripTimeFromDate(date) {
  var dateObject = new Date(date);
  dateObject.setUTCHours(0, 0, 0, 0);
  return dateObject;
}

exports.stripTimeFromDate = stripTimeFromDate;
var MONTHS;

(function (MONTHS) {
  MONTHS[MONTHS["JANUARY"] = 1] = "JANUARY";
  MONTHS[MONTHS["FEBRUARY"] = 2] = "FEBRUARY";
  MONTHS[MONTHS["MARCH"] = 3] = "MARCH";
  MONTHS[MONTHS["APRIL"] = 4] = "APRIL";
  MONTHS[MONTHS["MAY"] = 5] = "MAY";
  MONTHS[MONTHS["JUNE"] = 6] = "JUNE";
  MONTHS[MONTHS["JULY"] = 7] = "JULY";
  MONTHS[MONTHS["AUGUST"] = 8] = "AUGUST";
  MONTHS[MONTHS["SEPTEMBER"] = 9] = "SEPTEMBER";
  MONTHS[MONTHS["OCTOBER"] = 10] = "OCTOBER";
  MONTHS[MONTHS["NOVEMBER"] = 11] = "NOVEMBER";
  MONTHS[MONTHS["DECEMBER"] = 12] = "DECEMBER";
})(MONTHS = exports.MONTHS || (exports.MONTHS = {}));

exports.MONTH_TEXT_TRANSLATION = (_exports$MONTH_TEXT_T = {}, _defineProperty(_exports$MONTH_TEXT_T, MONTHS.JANUARY, 'janvier'), _defineProperty(_exports$MONTH_TEXT_T, MONTHS.FEBRUARY, 'février'), _defineProperty(_exports$MONTH_TEXT_T, MONTHS.MARCH, 'mars'), _defineProperty(_exports$MONTH_TEXT_T, MONTHS.APRIL, 'avril'), _defineProperty(_exports$MONTH_TEXT_T, MONTHS.MAY, 'mai'), _defineProperty(_exports$MONTH_TEXT_T, MONTHS.JUNE, 'juin'), _defineProperty(_exports$MONTH_TEXT_T, MONTHS.JULY, 'juillet'), _defineProperty(_exports$MONTH_TEXT_T, MONTHS.AUGUST, 'août'), _defineProperty(_exports$MONTH_TEXT_T, MONTHS.SEPTEMBER, 'septembre'), _defineProperty(_exports$MONTH_TEXT_T, MONTHS.OCTOBER, 'octobre'), _defineProperty(_exports$MONTH_TEXT_T, MONTHS.NOVEMBER, 'novembre'), _defineProperty(_exports$MONTH_TEXT_T, MONTHS.DECEMBER, 'décembre'), _exports$MONTH_TEXT_T);

function getMonthYearsForPeriod(start, end) {
  if (start.month > end.month && start.year >= end.year) {
    throw new Error('Invalid bounds');
  }

  var currentMonthYear = start;
  var monthYears = [];
  var count = 0;

  while ((currentMonthYear.year < end.year || currentMonthYear.year === end.year && currentMonthYear.month <= end.month) && count < 20) {
    monthYears.push(currentMonthYear);
    currentMonthYear = getNextMonthYear(currentMonthYear);
    count++;
  }

  return monthYears;
}

exports.getMonthYearsForPeriod = getMonthYearsForPeriod;

function getNextMonthYear(monthYear) {
  return {
    month: getNextMonth(monthYear.month),
    year: monthYear.month === MONTHS.DECEMBER ? monthYear.year + 1 : monthYear.year
  };
}

exports.getNextMonthYear = getNextMonthYear;

function getDateMonth(date) {
  switch (date.getMonth() + 1) {
    case 1:
      return MONTHS.JANUARY;

    case 2:
      return MONTHS.FEBRUARY;

    case 3:
      return MONTHS.MARCH;

    case 4:
      return MONTHS.APRIL;

    case 5:
      return MONTHS.MAY;

    case 6:
      return MONTHS.JUNE;

    case 7:
      return MONTHS.JULY;

    case 8:
      return MONTHS.AUGUST;

    case 9:
      return MONTHS.SEPTEMBER;

    case 10:
      return MONTHS.OCTOBER;

    case 11:
      return MONTHS.NOVEMBER;

    case 12:
      return MONTHS.DECEMBER;

    default:
      throw new Error("Invalid month: ".concat(date.getMonth()));
  }
}

exports.getDateMonth = getDateMonth;

function getNextMonth(month) {
  switch (month) {
    case MONTHS.JANUARY:
      return MONTHS.FEBRUARY;

    case MONTHS.FEBRUARY:
      return MONTHS.MARCH;

    case MONTHS.MARCH:
      return MONTHS.APRIL;

    case MONTHS.APRIL:
      return MONTHS.MAY;

    case MONTHS.MAY:
      return MONTHS.JUNE;

    case MONTHS.JUNE:
      return MONTHS.JULY;

    case MONTHS.JULY:
      return MONTHS.AUGUST;

    case MONTHS.AUGUST:
      return MONTHS.SEPTEMBER;

    case MONTHS.SEPTEMBER:
      return MONTHS.OCTOBER;

    case MONTHS.OCTOBER:
      return MONTHS.NOVEMBER;

    case MONTHS.NOVEMBER:
      return MONTHS.DECEMBER;

    case MONTHS.DECEMBER:
      return MONTHS.JANUARY;
  }
}