"use strict";

var _defineProperty = require("/var/www/vhosts/oyp.fr/deploy_tools/apps/calm-panda/releases/20241115110151/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _exports$INVOICE_STAT, _exports$DELIVERY_MOD, _exports$INVOICE_PRIN, _exports$BILLING_MODE, _exports$ORDER_ITEM_R, _exports$ORDER_CHANGE, _exports$PLATFORM_COD;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var types_1 = require("./types");

exports.INVOICE_STATUS_TRANSLATIONS = (_exports$INVOICE_STAT = {}, _defineProperty(_exports$INVOICE_STAT, types_1.INVOICE_STATUSES.NEW, "Nouveau"), _defineProperty(_exports$INVOICE_STAT, types_1.INVOICE_STATUSES.SENT, "Envoy\xE9e"), _defineProperty(_exports$INVOICE_STAT, types_1.INVOICE_STATUSES.PAID, "Pay\xE9e"), _defineProperty(_exports$INVOICE_STAT, types_1.INVOICE_STATUSES.REMINDED, "En relance"), _exports$INVOICE_STAT);
exports.DELIVERY_MODE_TRANSLATIONS = (_exports$DELIVERY_MOD = {}, _defineProperty(_exports$DELIVERY_MOD, types_1.ORDER_DELIVERY_MODES.FIXED_DATE, 'Date fixe'), _defineProperty(_exports$DELIVERY_MOD, types_1.ORDER_DELIVERY_MODES.GROUP_WITH_RECALCULATION, 'Groupé (avec recalcul)'), _exports$DELIVERY_MOD);
exports.INVOICE_PRINTCENTER_STATUS_TRANSLATIONS = (_exports$INVOICE_PRIN = {}, _defineProperty(_exports$INVOICE_PRIN, types_1.INVOICE_STATUSES.NEW, "Nouveau"), _defineProperty(_exports$INVOICE_PRIN, types_1.INVOICE_STATUSES.SENT, "Envoy\xE9e"), _defineProperty(_exports$INVOICE_PRIN, types_1.INVOICE_STATUSES.PAID, "Pay\xE9e"), _defineProperty(_exports$INVOICE_PRIN, types_1.INVOICE_STATUSES.REMINDED, "En retard de paiement"), _exports$INVOICE_PRIN);
exports.BILLING_MODES_TRANSLATIONS = (_exports$BILLING_MODE = {}, _defineProperty(_exports$BILLING_MODE, types_1.BILLING_MODES.IMMEDIATE, 'Immédiat'), _defineProperty(_exports$BILLING_MODE, types_1.BILLING_MODES.MONTHLY_TRANSFER, 'Virement mensuel'), _defineProperty(_exports$BILLING_MODE, types_1.BILLING_MODES.MONTHLY_DEBIT, 'Prélèvement mensuel'), _defineProperty(_exports$BILLING_MODE, types_1.BILLING_MODES.INTERNAL, 'Interne'), _exports$BILLING_MODE);
exports.PAYMENT_METHODS_TRANSLATIONS = _defineProperty({}, types_1.PAYMENT_METHODS.STRIPE, 'Stripe');
exports.ORDER_ITEM_RENEWAL_FAILURE_REASONS_TRANSLATIONS = (_exports$ORDER_ITEM_R = {}, _defineProperty(_exports$ORDER_ITEM_R, types_1.ORDER_ITEM_RENEWAL_FAILURE_REASONS.INVALID, 'Configuration invalide'), _defineProperty(_exports$ORDER_ITEM_R, types_1.ORDER_ITEM_RENEWAL_FAILURE_REASONS.NO_LONGER_AVAILABLE, 'Produit indisponible'), _exports$ORDER_ITEM_R);
exports.ORDER_CHANGE_NOTIFICATION_TYPE_TRANSLATIONS = (_exports$ORDER_CHANGE = {}, _defineProperty(_exports$ORDER_CHANGE, types_1.ORDER_CHANGE_NOTIFICATION_TYPE.ADDRESS, 'Adresse'), _defineProperty(_exports$ORDER_CHANGE, types_1.ORDER_CHANGE_NOTIFICATION_TYPE.ORDER_COMMENT, 'Com. commande'), _defineProperty(_exports$ORDER_CHANGE, types_1.ORDER_CHANGE_NOTIFICATION_TYPE.ORDER_ITEM_COMMENT, 'Com. produit'), _defineProperty(_exports$ORDER_CHANGE, types_1.ORDER_CHANGE_NOTIFICATION_TYPE.ORDER_ITEM_DESCRIPTION, 'Desc. produit'), _defineProperty(_exports$ORDER_CHANGE, types_1.ORDER_CHANGE_NOTIFICATION_TYPE.SHIPPING_DATE, "Date d'exp\xE9"), _exports$ORDER_CHANGE);
exports.PLATFORM_CODE_TRANSLATIONS = (_exports$PLATFORM_COD = {}, _defineProperty(_exports$PLATFORM_COD, types_1.PLATFORM_CODES.INPRINT, 'InPrint'), _defineProperty(_exports$PLATFORM_COD, types_1.PLATFORM_CODES.PRINTDOC, 'PrintDoc'), _defineProperty(_exports$PLATFORM_COD, types_1.PLATFORM_CODES.PPC, 'PPC'), _defineProperty(_exports$PLATFORM_COD, types_1.PLATFORM_CODES.PRINT_CENTER, 'PrintCenter'), _defineProperty(_exports$PLATFORM_COD, types_1.PLATFORM_CODES.ROLLUP, 'Rollup'), _exports$PLATFORM_COD);